<template>
  <div class="modal modal-center">
    <div class="modal-bg"></div>

    <div class="modal-block">
      <div class="modal-header">
        <h3>NC Loan Form</h3>
        <button class="btn btn-control" @click="$emit('close')">
          <img src="@/assets/icons/icon-remove.svg" alt="">
        </button>
      </div>
      <div class="modal-body pb-0">
        <div class="modal-body-container border-bottom-0">
          <iframe
            id="JotFormIFrame-232265729765063"
            title="NC Loan Form"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allowfullscreen="true"
            src="https://form.jotform.com/232265729765063"
            frameborder="0"
            style="min-width: 100%; height:800px; border:none;" scrolling="yes">
          </iframe>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <div>
          <base-button title="Close" action="secondary" @click-btn="submitForm"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "../../../components/BaseButton.vue";

export default {
  name: 'NCLoanModal',
  components: {BaseButton},
  data() {
    return {
      isConfirm: false
    }
  },
  methods: {
    submitForm() {
      this.$emit('close', true)
    }
  },
  mounted() {
    const iframe = document.getElementById('JotFormIFrame-232265729765063');


    iframe.addEventListener("load", function() {
      iframe.contentWindow.document.addEventListener("submit", function() {
        alert("Check");
      });
    });

    window.addEventListener("message", function(event) {
      if (event.origin === "http://127.0.0.1:7074" && event.data === "SubmitEventOccurred") {
        alert("Check");
      }
    });
  }
}
</script>


<style lang="scss" scoped>
.modal {
  &-block {
    height: fit-content!important;
    margin: 150px auto!important;
    overflow-y: hidden !important;

    p {
      color: #000000;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
    }
    .modal-footer {
      position: initial;
      bottom: unset;
    }
    .modal-header {
      position: initial;
    }
  }
}
input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
</style>
