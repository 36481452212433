<template>
  <div
    @click="$el.querySelector('input[type=file]').click()"
    @dragover.prevent
    @drop.prevent="handleDrop"
    @:dragleave.prevent
  >
    <div class="scanned-doc position-relative text-center">
      <input type="file" :id="fieldId" class="position-absolute" @change="submitFiles" multiple>
      <div class="scanned-doc-text" v-if="files.length > 0">
        <span>{{ files.length }}</span> file{{ (files.length > 1) ? '(s)' : '' }} selected
      </div>
      <div class="scanned-doc-text" v-else>
        Drag File here or <span>Browse</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileFieldNew",
  props: {
    fieldId: String,
    multiple: Boolean,
    files: Array
  },
  methods: {
    submitFiles(e) {
      let arr = [];
        for (let i = 0; i < e.target.files.length; i++) {
          arr.push(e.target.files[i])
        }
      this.$emit('change', arr);
    },
    handleDrop(e) {
      let arr = [];
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        arr.push(e.dataTransfer.items[i].getAsFile())
      }
      this.$emit('change', arr);
    },

  }
}
</script>

<style scoped>
input {
  top: 0;
  left: -100% !important;
}
</style>
