<template>
  <div class="alert alert-danger alert-dismissible" role="alert" :class="{'d-none': !isError}">
    <h4 class="alert-heading">Validation Errors</h4>
    <div v-if="errors.length > 0">
      <p v-for="(err, i) in errors" :key="'validationError' + i">
        <strong>{{ err.label }}:</strong> {{ err.message }}
      </p>
    </div>
    <p v-else>
      {{ error }}
    </p>
    <button @click="isError = false" type="button" class="btn-close" data-dismiss="alert" aria-label="Close"></button>
  </div>
</template>

<script>
export default {
name: "ValidationErrorsList",
  props: {
    errors: Array,
    error: String,
    isError: Boolean,
  },
}
</script>

<style scoped>

</style>
