<script>
export default {
  bind(el, value) {
    if (typeof value.value === 'string' && value.value.length > 0 && value.value !== 'NaN' && value.value !== 'undefined') {
      el.classList.add('input-filled');
    } else if (typeof value.value === 'number' && value.value !== 'undefined' && value.value !== 'NaN') {
      el.classList.add('input-filled');
    } else if (value.value === 'undefined' || value.value === 'NaN' || (value.value + '').length === 0) {
      el.classList.remove('input-filled');
    }
  },
  update(el, value) {
    if (typeof value.value === 'string' && value.value.length > 0 && value.value !== 'NaN' && value.value !== 'undefined') {
      el.classList.add('input-filled');
    } else if (typeof value.value === 'number' && value.value !== 'undefined' && value.value !== 'NaN') {
      el.classList.add('input-filled');
    } else if (value.value === 'undefined' || value.value === 'NaN' || (value.value + '').length === 0) {
      el.classList.remove('input-filled');
    }
  }
}
</script>
