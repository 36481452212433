<template>
  <div class="alert alert-danger text-center">
    <div v-if="errorContent.isNotFound">
      <h5 class="alert-heading">Loan is not found in our LendingPad records.</h5>
      <hr />
      <p class="text-danger">
        <strong>
          If you just updated the status, please note: it may take up to 5
          minutes to sync!
        </strong>
      </p>
      <button class="btn btn-control close-btn" @click="$emit('close')">
        <img src="@/assets/icons/icon-close.png" alt="" />
      </button>
    </div>
    <div v-else>
      <h5 class="alert-heading">Loan is not in funded status in LendingPad.</h5>
      <small>
        loan id :
        <strong class="text-lowercase">{{ errorContent.loanId }}</strong>
      </small>
      <hr />
      <p class="text-danger">
        <strong>
          If you just updated the status, please note: it may take up to 5
          minutes to sync!
        </strong>
      </p>
      <button class="btn btn-control close-btn" @click="$emit('close')">
        <img src="@/assets/icons/icon-close.png" alt="" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckFundedErrorAlert",
  props: {
    errorContent: Object
  }
};
</script>

<style lang="scss" scoped>
.alert {
  .close-btn {
    margin: auto !important;
  }
}
</style>
