<template>
  <div class="modal-block">
    <div class="modal-body">
      <div class="modal-body-container border-0">
        <div class="text-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "ModalSpinner"
}
</script>

<style scoped>

</style>
