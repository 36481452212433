<template>
  <button
    class="base-button"
    type="button"
    :class="[action, size]"
    @click="$emit('click-btn')"
    :disabled="disabled"
  >
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
    {{ title }}
    <slot name="icon"></slot>
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    title: String,
    action: String,
    disabled: Boolean,
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    }
  }
};
</script>
