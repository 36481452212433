export default {
    paging: {
        currentPage: 1,
        from: 0,
        limit: 10,
        offset: 0,
        page: 1,
        perPage: 10,
        to: 0,
        total: 0
    },
    status: {
        label: 'Active',
        value: 'pending'
    },
    assigned: {
        value: '',
        label: 'Assigned to me'
    },
    sorted: {
        value: "lastName_ASC",
        label: "Last name ASC"
    },
    lender: {
        id: '',
        name: "All lenders"
    }
};