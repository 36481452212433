<template>
  <div>
    <div class="page-header d-flex align-items-center">
      <div class="row h-100 w-100">
        <div class="col-12 col-md-4 mt-2 my-md-auto">
          <h3 class="page-header-title">Funding Requests</h3>
        </div>
        <div class="col-12 col-md-8 text-end mt-3 mb-3 my-md-auto pe-0">
          <div class="page-header-buttons d-flex align-items-center justify-content-md-end">
            <base-button title="New Funding Request" action="primary"
                         @click-btn="modals.newFundingModal = true" v-if="!isGranted('ROLE_SOFT_DELETED')" />
            <template v-if="isGranted(ROLE_FUNDING_REQUEST_PROCESS)">
              <base-button title="Run Bot" action="secondary" @click-btn="runBot" class="ms-2"
                           v-tippy content="Process fundings using previously uploaded transactions" />
              <base-button title="Upload Chase Transactions" action="secondary-default" class="ms-2" @click-btn="modals.uploadChase = true">
                <template #icon>
                  <img src="@/assets/icons/icon-sync white.svg" class="ms-2" alt="">
                </template>
              </base-button>
            </template>
            <base-button title="Export" action="secondary" @click-btn="modals.exportFilterModal = true" class="ms-2" v-if="isGranted('ROLE_BOOKKEEPER')" />
          </div>
        </div>
      </div>
      <transition name="component-fade" mode="out-in">
        <new-funding-modal
          v-if="modals.newFundingModal === true"
          @close="refreshTable()"/>
      </transition>

      <transition name="component-fade" mode="out-in">
        <upload-chase-modal
          v-if="modals.uploadChase === true"
          @close="modals.uploadChase = false"/>
      </transition>

      <transition name="component-fade" mode="out-in">
        <export-filter-modal
          v-if="modals.exportFilterModal === true"
          @close="modals.exportFilterModal = false"/>
      </transition>
    </div>
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li :class="{ 'active': isActive('funding_requests_pending') }"
            @click="gotoRoute('funding_requests_pending')">
          Pending
        </li>
        <li :class="{ 'active': isActive('funding_requests_proceed') }" v-if="isGranted('ROLE_SOFT_DELETED') === false"
            @click="gotoRoute('funding_requests_proceed')">
          Processed
        </li>
        <li :class="{ 'active': isActive('funding_requests_deleted') }" v-if="isGranted('ROLE_SOFT_DELETED') === false"
            @click="gotoRoute('funding_requests_deleted')">
          Deleted
        </li>
        <li v-if="isGranted('ROLE_BRANCH_MANAGER') && isGranted('ROLE_SOFT_DELETED') === false"
            :class="{ 'active': isActive('funding_requests_branch') }"
            @click="gotoRoute('funding_requests_branch')">
          Branch
        </li>
        <li class="auto-precessed"
            :class="{ 'active': isActive('funding_requests_auto_processed') }"
            @click="gotoRoute('funding_requests_auto_processed')"
            v-if="$store.getters.getAutoPrecessedFundingIds.length">
          Auto-processed
        </li>
      </ul>
    </div>
    <vue-page-transition name="fade" :key="componentKey">
      <router-view name="funding_request"></router-view>
    </vue-page-transition>
  </div>
</template>

<script>
import NewFundingModal from "./modals/NewFundingModal";
import UploadChaseModal from "./modals/UploadChaseModal";
import ExportFilterModal from "./modals/ExportFilterModal";
import BaseButton from '../../components/BaseButton.vue';

export default {
  name: "FundingRequests",
  components: {NewFundingModal, UploadChaseModal, ExportFilterModal, BaseButton},
  data() {
    return {
      componentKey: 1,
      modals: {
        newFundingModal: false,
        uploadChase: false,
        exportFilterModal: false
      }
    }
  },
  beforeMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const openNewFundingModal = urlParams.get('newFunding');
    if (openNewFundingModal !== null) {
      this.modals.newFundingModal = Boolean(openNewFundingModal)
    }
  },
  methods: {
    gotoRoute(routeName) {
      this.$router.push({name: routeName}).catch(() => {
      });
    },
    isActive(routeName) {
      return this.currentPage === routeName
    },
    runBot() {
      this.$http
        .post('/api/v1/funding-requests/check-wires')
        .then(res => {
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: res.data.message,
            status: res.data.status,
            delay: 2000
          });
          if (res.data.ids) {
            this.$store.dispatch('SET_AUTO_PROCESSED_FUNDING_IDS', res.data.ids)
            this.$router.push({name: 'funding_requests_auto_processed'})
          }
        })
        .catch()
    },
    refreshTable() {
      this.modals.newFundingModal = false;
      this.componentKey++;
    },
  },
  computed: {
    currentPage() {
      return this.$route.name
    }
  },
}
</script>

<style lang="scss" scoped>
.upload-chase {
  max-width: 240px;
  width: 100%;
  min-height: 36px;
  height: 36px;
  align-items: center;
  justify-content: space-between;
  color: rgba(255, 255, 255, 1);
  font-size: 0.75rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 0.875rem;
  border: 1px solid rgba(23, 87, 191, 1);
  border-radius: 8px;
  background-color: rgba(38, 111, 229, 1);

  @media (max-width: 568px) {
    max-width: fit-content;

    div:not(.upload-chase-icon) {
      display: none;
    }

    .upload-chase-icon {
      border-left: none;
    }
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-left: 1px solid rgba(23, 87, 191, 1);
  }
}

//.auto-precessed {
//  font-weight: 500;
//  color: red;
//  background-color: #0A48AE;
//}

@media screen and (max-width: 568px) {
  .btn.btn-outline-dark {
    font-size: 0.625rem;
    line-height: 0.6875rem;
  }
  .upload-chase {
    font-size: 0.55rem;
  }
}

</style>
