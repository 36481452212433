import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./routes";
import store from './../store/index'

Vue.use(VueRouter)

let roles = store.getters.getRoles
if (typeof roles === 'object' && !Array.isArray(roles)) {
  roles = Object.values(roles)
}

let isSoftDeleted = roles.includes('ROLE_SOFT_DELETED')

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {

    if (router.resolve(to).resolved.matched.length === 0) {
        next({ name: 'page_not_found' })
    }

    if (to.query.token && !store.getters.getToken) {
        store.dispatch('SET_TOKEN', to.query.token)
        window.location = to.path
        return
    }

    if (to.name === 'credit_auth_submit_document') {
      next();
    }
    if (to.name === 'e-sign') {
      next();
    }
    if (to.name !== 'auth.login'
      && !store.getters.getToken
      && to.name !== 'credit_auth_submit_document'
      && to.name !== 'e-sign'
      && to.name !== 'rate_quote_funnel'
      && to.name !== 'reset_pass')
    {
      next({ name: 'auth.login' })
    } else if (to.name === 'auth.login' && store.getters.getToken)
    {
      if (isSoftDeleted) {
        next({ name: 'funding_requests_pending' })
      } else {
        next({ name: 'dashboard' })
      }
    } else {
      if (isSoftDeleted && to.name !== 'funding_requests_pending') {
        next({ name: 'funding_requests_pending' })
      } else {
        next()
      }
    }
})

export default router
