export const changePagination = ({ commit }, { paging }) => {
    commit('changePagination', paging);
};

export const changeStatus = ({ commit }, { status }) => {
    commit('changeStatus', status);
};

export const changeAssigned = ({ commit }, { assigned }) => {
    commit('changeAssigned', assigned);
};

export const changeSorted = ({ commit }, { sorted }) => {
    commit('changeSorted', sorted);
};

export const changeLender = ({ commit }, { lender }) => {
    commit('changeLender', lender);
};

export default {
    changePagination,
    changeStatus,
    changeAssigned,
    changeSorted,
    changeLender,
};