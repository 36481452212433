<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <ModalSpinner v-if="ui.loading"></ModalSpinner>

    <div class="modal-block" id="new-funding-form-modal-block">
      <div class="modal-header" v-show="ui.loading === false">
        <h3>Add Funding Request</h3>
      </div>
      <form @submit="submit" class="modal-form" v-show="ui.loading === false">
        <div class="modal-body" id="modal-body-scroll">
          <div class="modal-body-container" v-if="isAllowedToChangeAuthor">
            <div class="form-group">
              <label for="fieldAgent">Agent</label>
              <multiselect placeholder="Select Agent" id="fieldAgent" track-by="id" label="fullName"
                           v-model="funding.agent"
                           @select="getLoansByAgent"
                           :allowEmpty="false"
                           :options="agents"
                           :class="{selected: Object.keys(funding.agent).length > 0}"
                           :preselect-first="true"
                           :show-labels="false">
              </multiselect>
            </div>
          </div>
          <div class="modal-body-container mt-3">
            <div class="modal-body-container_title">Loan Information</div>
            <div class="form-group col-md-6">
              <label for="fieldLoan">Loan</label>
              <multiselect placeholder="Select Loan" id="fieldLoan" track-by="id" label="name"
                           v-model="funding.loan"
                           :allowEmpty="false"
                           :options="loans"
                           :class="{selected: Object.keys(funding.loan).length > 0}"
                           :loading="ui.loanMultiselectLoading"
                           :show-labels="false" @select="checkFundingRequest">
              </multiselect>
            </div>
            <div class="alert alert-warning mt-2" role="alert" v-if="funding.loan && funding.loan.esign && funding.loan.esign.status === 'pending'">
              Action Required: E-Sign Credit Authorization and Privacy Notice
            </div>
            <div class="alert alert-warning mt-2" role="alert" v-if="loanIsValid === false">
              Missing validation from originating Loan Officer.  Please contact the originating loan officer.
            </div>
            <div class="row mt-3">
              <div class="form-group col-md-6 disabled">
                <label for="fieldBorrowerFirstName">Borrower First Name</label>
                <input type="text" id="fieldBorrowerFirstName"
                       v-model="funding.loan.borrowerFirstName"
                       class="form-control disabled" disabled>
              </div>
              <div class="form-group col-md-6 disabled">
                <label for="fieldBorrowerLastName">Borrower Last Name</label>
                <input type="text" id="fieldBorrowerLastName"
                       v-model="funding.loan.borrowerLastName"
                       class="form-control disabled" disabled>
              </div>
              <div class="form-group col-md-6 mt-3 disabled">
                <label for="fieldLender">Lender</label>
                <input type="text" id="fieldLender"
                       v-input-filled:value="funding.loan.lender"
                       v-model="funding.loan.lender"
                       class="form-control disabled" disabled>
              </div>
              <div class="form-group col-md-6 mt-3">
                <label for="fieldLoanNumber">Loan Number</label>
                <input type="text" id="fieldLoanNumber" class="form-control"
                       v-input-filled:value="funding.loan.loanNumber"
                       v-model="funding.loan.loanNumber"
                       autocomplete="off">
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="modal-body-container_title">Payment Details</div>
            <div class="form-group col-md-6">
              <label>Payment Type</label>
              <div class="d-flex">
                <span class="check-btn" :class="{ active: funding.type === 'wire' }"
                      v-if="funding.loan.lenderType !== 'Correspondent'"
                      @click="setType('wire')">Wire</span>
                <span class="check-btn" :class="{ active: funding.type === 'check' }"
                      v-if="funding.loan.lenderType !== 'Correspondent'"
                      @click="setType('check')">Check</span>
                <span class="check-btn" :class="{ active: funding.type === 'correspondent' }"
                      v-if="funding.loan.lenderType === 'Correspondent'"
                      @click="setType('correspondent')">Correspondent</span>
              </div>
            </div>
            <div class="alert alert-warning mt-2" role="alert"
                 v-if="!isGranted('ROLE_PROCESS_FUNDING_REQUESTS') && funding.type === 'correspondent'">
              Only Funder Can Process Correspondent Funding.
            </div>
            <div class="form-group col-md-6 mt-3">
              <label for="fieldWireDate">Wire Date</label>
              <date-range-picker
                ref="picker"
                v-model="dateRange"
                class="daterange-control"
                id="fieldWireDate"
                opens="center"
                :singleDatePicker="true"
                :auto-apply="true"
                :timePicker="false"
                :time-picker24-hour="false"
                :ranges="false"
                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
              >
                <template v-slot:input="picker">
                  {{ picker.startDate | date }}
                  <img src="@/assets/icons/icon-calender.svg" alt="">
                </template>
              </date-range-picker>
            </div>
            <div class="form-group col-md-6 mt-3" v-if="funding.type !== 'correspondent' || isGranted('ROLE_FUNDER')">
              <label for="fieldWireAmount">Wire Amount</label>
              <div class="input-with-icon mb-2 w-75">
                <span class="icon">$</span>
                <input type="number" class="form-control" id="fieldWireAmount"
                       v-input-filled:value="funding.wireAmount"
                       v-model.number="funding.wireAmount"
                       @change="calcTotalDueToSalesperson"
                       step="0.01">
              </div>
            </div>
            <div class="form-group col-md-6 mt-3">
              <label for="fieldTotalCharged">Total origination charged</label>
              <div class="input-with-icon mb-2 w-75">
                <span class="icon">$</span>
                <input type="number"
                       class="form-control"
                       id="fieldTotalCharged"
                       v-input-filled:value="funding.totalOriginationCharged"
                       v-model.number="funding.totalOriginationCharged"
                       @change="calcTotalDueToSalesperson"
                       step="0.01">
              </div>
            </div>
            <div class="form-group col-md-7 mt-3">
              <label for="fieldReimbursed" class="w-100">How much is being reimbursed for the credit report?</label>
              <div class="input-with-icon mb-2 w-75">
                <span class="icon">$</span>
                <input type="number"
                       class="form-control"
                       id="fieldReimbursed"
                       v-input-filled:value="funding.reimbursedAmount"
                       v-model.number="funding.reimbursedAmount"
                       @change="calcTotalDueToSalesperson"
                       step="0.01">
              </div>
            </div>
          </div>
          <div class="modal-body-container mt-3">
            <label for="reimbursedAppraisal">Are you being reimbursed for the appraisal fee?</label>
            <div class="d-flex" id="reimbursedAppraisal">
              <span class="check-btn" :class="{ active: funding.isAppraisalFee }"
                    @click="funding.isAppraisalFee = true">Yes</span>
              <span class="check-btn" :class="{ active: !funding.isAppraisalFee }"
                    @click="funding.isAppraisalFee = false">No</span>
            </div>
            <div class="col-md-6 mt-3" v-if="funding.isAppraisalFee">
              <label for="fieldAppraisalFee">
                How much is the appraisal fee/How much of the appraisal is being paid back to you?
              </label>
              <div class="form-group input-with-icon mb-2">
                <span class="icon">$</span>
                <input type="number" class="form-control" id="fieldAppraisalFee"
                       @change="calcTotalDueToSalesperson"
                       v-input-filled:value="funding.appraisalFee"
                       v-model.number="funding.appraisalFee"
                       step="0.01">
              </div>
            </div>
          </div>
          <div class="modal-body-container mt-3">
            <label for="cure">Is there a tolerance cure in this file that was subtracted from the broker check?</label>
            <div class="d-flex" id="cure">
              <span class="check-btn" :class="{ active: funding.isToleranceCure }"
                    @click="funding.isToleranceCure = true">Yes</span>
              <span class="check-btn" :class="{ active: !funding.isToleranceCure }"
                    @click="funding.isToleranceCure = false">No</span>
            </div>
            <div class="col-md-6 mt-3" v-if="funding.isToleranceCure === true">
              <label for="fieldCure">How much is the cure?</label>
              <div class="form-group input-with-icon mb-2">
                <span class="icon">$</span>
                <input type="number" class="form-control" id="fieldCure"
                       @change="calcTotalDueToSalesperson"
                       v-input-filled:value="funding.cureAmount"
                       v-model.number="funding.cureAmount"
                       step="0.01">
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="form-group disabled">
              <label for="fieldTotalDue">Total Due to Salesperson</label>
              <div class="form-group input-with-icon mb-2">
                <span class="icon">$</span>
                <input type="number" class="form-control"
                        id="fieldTotalDue"
                        placeholder="0.00"
                        v-model.number="funding.dueToSalesperson"
                        step="0.01" disabled>
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <label for="isOrigination">Is this origination from a Lendingtree lead?</label>
            <div class="d-flex" id="isOrigination">
              <span class="check-btn" :class="{active: funding.isOrigination}"
                    @click="funding.isOrigination = true">Yes</span>
              <span class="check-btn" :class="{active: !funding.isOrigination }"
                    @click="funding.isOrigination = false">No</span>
            </div>
            <div class="form-group mt-3" v-if="funding.isOrigination === true">
              <label for="fieldLendingTree">QF Number for original lead</label>
              <input type="text" class="form-control" id="fieldLendingTree"
                     v-input-filled:value="funding.qfNumber"
                     placeholder="LendingTree QF Number" v-model="funding.qfNumber">
            </div>
          </div>
          <div class="modal-body-container">
            <label for="fieldNote">Note</label>
            <textarea id="fieldNote" cols="30" rows="6" placeholder="Write a note (Optional)"
                      v-input-filled:value="funding.note"
                      class="form-control" v-model="funding.note"></textarea>
          </div>
          <div class="modal-body-container">
            <label for="isReminder">Send Google Review Reminder?</label>
            <div class="d-flex" id="isReminder">
              <span class="check-btn" :class="{ active: funding.isGoogleReminder }" @click="funding.isGoogleReminder = true">Yes</span>
              <span class="check-btn" :class="{ active: !funding.isGoogleReminder }" @click="funding.isGoogleReminder = false">No</span>
            </div>
          </div>
          <div class="modal-body-container">
            <CheckFundedErrorAlert
              v-if="ui.confirmIsFundedError.status"
              :errorContent="ui.confirmIsFundedError"
              @close="closeConfirmIsFundedAlert"
            />

            <label for="confirmFunded">Confirm Funded status in LendingPad.</label>
            <div class="d-flex" id="confirmFunded">
              <button class="btn bg-green" type="button" @click="confirmFunded" :disabled="ui.confirmFunded">
                <span v-if="ui.confirmFunded">
                  Syncing with LendingPad, Please wait
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                </span>
                <span v-else>Confirm</span>
              </button>
            </div>
          </div>
          <div class="modal-body-container  " v-if="this.funding.loan.state === 'NC'">
            <div>
              <label for="NCForm">Complete Mandatory NC Compliance Form</label>
              <div class="d-flex" id="NCForm">
                <button class="btn btn-primary" @click="showNCModal">NC Compliance Form</button>
              </div>
              <div class="d-flex mt-3 align-items-center">
                <div class="">
                  <input class="form-check" type="checkbox" v-model="NcModalConfirmed" id="fieldNCConfirm" required>
                </div>
                <div>
                  <label for="fieldNCConfirm" class="mb-0">
                    I confirm that the NC Compliance Form has been completed and submitted
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <label>Upload Signed Closing Package</label>
            <file-field-new v-if="!funding.loan.closingDoc" :files="scannedDoc" field-id="new-funding-scanned-doc" @change="handleFile" />
            <div v-else>
              <b>Closing docs already uploaded and attached to loan.</b>
            </div>
            <label class="mt-4">Upload Signed Initial Disclosures</label>
            <file-field-new :files="initialDisclosure" field-id="new-funding-initial-disclosure" @change="handleInitialDisclosureFile" />
          </div>
          <div class="modal-body-container understand pb-0">
            <div class="d-flex">
              <div class="">
                <input class="form-check" type="checkbox" v-model="docsUploadedIntoSharePoint" id="fieldAccept" required>
              </div>
              <div>
                <label for="fieldAccept">
                  I acknowledge that all stips and loan documents are uploaded into SharePoint.
                </label>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <input class="form-check" type="checkbox" v-model="isAgree" id="FieldUnderstand" required>
              </div>
              <div>
                <label for="FieldUnderstand">
                  I understand it is my responsibility to be aware of lenders’
                  individual Early Payoff policies and it is my responsibility to
                  actively avoid early payoffs and I understand that I will be required
                  to return 100% of the commission I earned on this funding if this loan is paid off Early.
                </label>
              </div>
            </div>
          </div>
          <div class="p-3 alert-danger" v-if="!isWireCorrect">
            Looks like wire amount should be {{ convertToCurrency(expectedWireAmount) }}
            but {{ convertToCurrency(givenWireAmount) }} given.
            The wire is the sum of the origination plus reimbursements minus cures – check to see if either your
            origination amount, cure, or your reimbursement is incorrect.
          </div>

          <ValidationErrorsList :error="ui.form.error" :errors="ui.form.errors" :isError="ui.form.isError"/>

        </div>
        <div class="modal-footer d-flex justify-content-start" v-if="ui.loading === false">
          <base-button type="submit" title="Submit Funding Request" action="secondary-default"
                       :loading="ui.form.loading"
                       :disabled="!isAgree || !docsUploadedIntoSharePoint || !isWireCorrect || !funding.isFunded || (funding.loan && funding.loan.esign && funding.loan.esign.status === 'pending' || (!NcModalConfirmed && funding.loan.state === 'NC'))" />
          <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
        </div>
      </form>
    </div>
    <n-c-loan-modal v-if="ui.showNCModal" @close="closeModal" />
  </div>
</template>

<script>
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker';
import ValidationErrorsList from "../../../components/form/ValidationErrorsList";
import ModalSpinner from "../../../components/modal/ModalSpinner";
import PerfectScrollbar from 'perfect-scrollbar';
import CheckFundedErrorAlert from "./CheckFundedErrorAlert";
import FileFieldNew from '../../../components/FileFieldNew.vue';
import BaseButton from '../../../components/BaseButton.vue';
import NCLoanModal from "./NCLoanModal.vue";

export default {
  name: "NewFundingModal",
  components: {
    NCLoanModal,
    ModalSpinner, ValidationErrorsList, Multiselect, DateRangePicker, CheckFundedErrorAlert, FileFieldNew, BaseButton},
  props: {
    loanId: {
      type: Number,
      required: false
    },
    agentId: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      isAllowedToChangeAuthor: false,
      NcModalConfirmed: false,
      agents: [],
      loans: [],
      isAgree: false,
      docsUploadedIntoSharePoint: false,
      expectedWireAmount: null,
      givenWireAmount: null,
      isWireCorrect: true,
      scannedDoc: [],
      initialDisclosure: [],
      funding: {
        agent: {id: this.$store.getters.getUserId, fullName: this.$store.getters.getFullName},
        loan: {},
        type: null,
        wireAmount: 0,
        totalOriginationCharged: '',
        reimbursedAmount: 0,
        isAppraisalFee: false,
        appraisalFee: '',
        isToleranceCure: false,
        cureAmount: '',
        dueToSalesperson: '',
        isOrigination: false,
        qfNumber: '',
        isFunded: false,
        isGoogleReminder: true,
        note: ''
      },
      dateRange: {
        startDate: new Date(),
        endDate: new Date()
      },
      loanIsValid: null,
      ui: {
        loading: false,
        confirmFunded: false,
        loanMultiselectLoading: false,
        ps: null,
        showNCModal: false,
        form: {
          loading: false,
          isSuccess: false,
          isError: false,
          error: '',
          errors: [],
        },
        confirmIsFundedError: {
          status: false,
          loanId: '',
          isNotFound: false
        }
      },
      isConfirmed: false
    }
  },
  methods: {
    showNCModal(e) {
      e.preventDefault();
      this.ui.showNCModal = true
    },
    loadFormData() {
      this.ui.loading = true;
      this.$http
        .get('/api/v1/funding-requests/new')
        .then(res => {
          this.isAllowedToChangeAuthor = res.data.isAllowedToChangeAuthor
          this.getLoansByAgent(this.funding.agent)
          this.agents = res.data.agents

          if (this.agentId) {
            let agent = this.agents.find(agent => agent.id === this.agentId)
            if (agent) {
              this.funding.agent = agent
              this.getLoansByAgent(agent)
            }
          }

          this.ui.loading = false;
        })
        .catch()
    },
    closeModal() {
      this.ui.showNCModal = false
    },
    confirmFunded() {
      if (this.funding.loan && this.funding.loan.id > 0) {
        this.ui.confirmFunded = true
        this.funding.isFunded = false
        this.closeConfirmIsFundedAlert()

        this.$http
        .get(`/api/v1/funding-requests/confirm-isFunded/${this.funding.loan.id}`)
        .then(res => {
          if (res.data.isFunded && res.data.isFunded === true) {
            this.funding.isFunded = true
            this.alertSuccess(`The Loan is in Funded Status in LendingPad. Loan Id : ${res.data.loanId}`, 6000)
          } else {
            this.funding.isFunded = false
            this.ui.confirmIsFundedError.loanId = res.data.loanId
            this.ui.confirmIsFundedError.status = true
          }
        })
        .catch(() => {
          this.funding.isFunded = false
          this.ui.confirmIsFundedError.isNotFound = true
          this.ui.confirmIsFundedError.status = true
        })
        .finally(() => this.ui.confirmFunded = false )
      } else {
        this.alertError('Please select loan first!')
      }
    },
    submit(e) {
      e.preventDefault();

      if (this.funding.loan.state === 'NC' && this.NcModalConfirmed === false) {
        this.ui.showNCModal = true
      } else {
        this.addFunding(e)
      }
    },
    addFunding(e) {
      if (e) {
        e.preventDefault();
      }

      this.ui.form.loading = true
      let formData = new FormData();
      let fr = this.funding
      formData.append('agentId', fr.agent.id)
      formData.append('loanId', fr.loan.id)
      formData.append('loanNumber', fr.loan.loanNumber)
      formData.append('type', fr.type)
      formData.append('wireDate', this.formatDate(this.dateRange.startDate))
      formData.append('wireAmount', fr.wireAmount)
      formData.append('totalOriginationCharged', fr.totalOriginationCharged)
      formData.append('reimbursedAmount', fr.reimbursedAmount)
      formData.append('isAppraisalFee', fr.isAppraisalFee)
      formData.append('appraisalFee', fr.appraisalFee)
      formData.append('isToleranceCure', fr.isToleranceCure)
      formData.append('cureAmount', fr.cureAmount)
      formData.append('dueToSalesperson', fr.dueToSalesperson)
      formData.append('itIsFromLendingTree', fr.itIsFromLendingTree)
      formData.append('lendingTreeNumber', fr.qfNumber)
      formData.append('note', fr.note)
      formData.append('isPointUpdated', fr.isPointUpdated)
      formData.append(`scanned_doc`, this.scannedDoc.pop())
      formData.append(`initial_disclosure_doc`, this.initialDisclosure.pop())
      formData.append('isGoogleReminder', fr.isGoogleReminder)

      this.ui.form.isError = false
      this.ui.form.error = ''
      this.ui.form.errors = []

      this.$http
        .post(`/api/v1/funding-requests/new`, formData)
        .then(() => {
          this.$emit('close', true)
        })
        .catch(err => {
          let validation = this.getErrorsFromResponse(err.response)
          this.ui.form.isError = true
          this.ui.form.error = validation.error
          this.ui.form.errors = validation.errors

          let container = this.$el.querySelector("#new-funding-form-modal-block");
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: this.ui.form.error,
            status: 'error',
            delay: 2000
          });

          this.$nextTick(() => {
            container.scrollTop = 1000 + container.scrollHeight;
          });
        })
        .finally(() => {
          this.ui.form.loading = false
        })
    },
    getLoansByAgent(option = null) {
      this.ui.loanMultiselectLoading = true
      this.funding.agent = option
      this.funding.loan = {}
      this.$http
        .get('/api/v1/form/loans', {params: {agentId: option ? option.id : null}})
        .then(res => {
          this.ui.loanMultiselectLoading = false
          this.loans = res.data

          if (this.loanId) {
            let loan = this.loans.find(loan => loan.id === this.loanId)
            if (loan) {
              this.funding.loan = loan
            }
          }
        })
    },
    closeConfirmIsFundedAlert() {
      this.ui.confirmIsFundedError.loanId = ''
      this.ui.confirmIsFundedError.loanNumber = ''
      this.ui.confirmIsFundedError.status = false
    },
    setType(type) {
      this.funding.type = type
    },
    calcTotalDueToSalesperson() {
      if (!this.funding.wireAmount || !this.funding.totalOriginationCharged) {
        return
      }
      this.$http
        .post('/api/v1/funding-requests/total-due', {
          totalOriginationCharged: this.funding.totalOriginationCharged,
          reimbursedAmount: this.funding.reimbursedAmount,
          appraisalFee: this.funding.appraisalFee,
          cureAmount: this.funding.cureAmount,
          wireAmount: this.funding.wireAmount,
          agent: this.funding.agent.id
        })
        .then(res => {
          this.expectedWireAmount = res.data.expectedWireAmount
          this.givenWireAmount = res.data.givenWireAmount

          let ignoreByRole = this.isGranted('ROLE_PROCESS_FUNDING_REQUESTS')
            || this.isGranted('ROLE_CEO')
            || this.isGranted('ROLE_CEO_ASSISTANT')
            || this.isGranted('ROLE_CLOSER')

          if (ignoreByRole && this.funding.type === 'correspondent') {
            this.isWireCorrect = true
          } else {
            this.isWireCorrect = res.data.isWireCorrect
          }
          this.funding.dueToSalesperson = res.data.totalDueToSalesperson
        })
        .catch(err => {
          if (err.response.data.fundingRequest.totalDueToSalesperson) {
            this.pushAlert('error', err.response.data.fundingRequest.totalDueToSalesperson, 3000)
          } else {
            this.pushAlert('error', err.response.data)
          }
        })
    },
    handleFile(files) {
      this.scannedDoc = files;
    },
    handleInitialDisclosureFile(files) {
      this.initialDisclosure = files;
    },
    checkFundingRequest(option) {
      this.$http.get(`/api/v1/loans/validate-referral-loan/${option.id}`)
        .then((response) => {
          this.loanIsValid = response.data.isValid
          this.funding.loan.esign = response.data.esign
        })
        .catch()
    }
  },
  beforeMount() {
    this.loadFormData()
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
  },
  filters: {
    date(date) {
      if (date) {
        return moment(String(date)).format('MM/DD/YYYY')
      } else {
        return moment().format('MM/DD/YYYY')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#modal-body-scroll {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
}
.modal-body-container::v-deep .multiselect {
  &.selected {
    .multiselect__tags {
      background-color: #F3F3F4;
    }

    .multiselect__single {
      background-color: #F3F3F4;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
    }

    input {
      background-color: #F3F3F4;
    }
  }

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__tags {
    padding: 10px 40px 0 15px;
    transition: all .3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__select {
    &:before {
      top: 17%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}
.modal-form {
    position: relative;
    width: 772px;
}

::v-deep {
  .scanned-doc input {
    left: 0;
  }
}

.scanned-doc {
  &::v-deep {
    input {
      left: 0;
    }
  }

}
.form-check {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
</style>
