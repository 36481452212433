export function getBackendUrl() {
  let currentDomain = window.location.hostname
  let isStaging = currentDomain.includes('vision.staging')
  let apiUrl = process.env.VUE_APP_API_URL
  if (isStaging) {
    apiUrl = apiUrl.replace(
      'clearmortgagecapital.com',
      'staging.clearmortgagecapital.com'
    )
  }

  return apiUrl;
}
