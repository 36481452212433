<template>
  <div class="modal modal-center" id="uploadChase">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-body">
        <div class="modal-title">Upload Chase Transactions</div>
        <file-field-new
          :multiple="false"
          :files="files"
          class=mt-4
          field-id="chase-upload"
          @change="submitStips"
        />
        <div class="d-flex mt-4">
          <base-button title="Upload" action="secondary-default" :disabled="file === '' || file === null" @click-btn="saveForm" :loading="ui.saveLoading" />
          <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue';
import FileFieldNew from '../../../components/FileFieldNew.vue';
export default {
  name: "UploadChase",
  components: { FileFieldNew, BaseButton },
  data() {
    return {
      ui: {
        saveLoading: false,
      },
      file: '',
      files: [],
    };
  },
  methods: {
    handleFile() {
      this.file = this.$refs.chase.files[0];
    },
    submitStips(files) {
      this.files = files;
    },
    saveForm() {
      this.ui.saveLoading = true;
      let formData = new FormData();
      formData.append("file", this.file);

      this.$http
        .post("/api/v1/funding-requests/upload-chase-statement", formData)
        .then((res) => {
          this.$store.dispatch(
            "OPEN_STATUS_NOTIFICATION",
            (this.statusNotification = {
              msg: res.data.message,
              status: res.data.status,
              delay: 3000,
            })
          );
          this.$emit("close");
        })
        .catch((err) => {
          this.$store.dispatch(
            "OPEN_STATUS_NOTIFICATION",
            (this.statusNotification = {
              msg: err.response.data.message,
              status: err.response.data.status,
              delay: 3000,
            })
          );
        })
        .finally(() => {
          this.ui.saveLoading = false;
          this.file = null;
        });
    },
  },
  watch: {
    files() {
      this.file = this.files[0];
    }
  }
};
</script>

<style lang="scss">
#uploadChase {
  .modal-block {
    height: 206px;
    max-width: 384px;
    width: 100%;
    border: 0.0625rem solid rgba(239, 239, 239, 1);
    border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.07);
    margin: 100px auto;
    overflow: hidden;
  }
  .modal-body {
    padding: 30px;
    .modal-title {
      color: rgba(0, 0, 0, 1);
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 1.5rem;
    }
  }
}
</style>
