export default {
    changePagination(state, paging) {
        Object.assign(state.paging, paging);
    },
    changeStatus(state, status) {
        Object.assign(state.status, status);
    },
    changeAssigned(state, assigned) {
        Object.assign(state.assigned, assigned);
    },
    changeSorted(state, sorted) {
        Object.assign(state.sorted, sorted);
    },
    changeLender(state, lender) {
        Object.assign(state.lender, lender);
    }
};