import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import appConfig from './appConfig';
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: {
      id: 0,
      firstName: '',
      lastName: '',
      email: '',
      photo: '',
      token: '',
      roles: [],
      position: '',
      nmls: '',
      phone: '',
      dre: '',
      isAgreeToTerms: '',
      isNewCRM: false,
      isFirstTime: false,
      flyerId: null,
    },
    counters: {
      loans: 0,
      tickets: 0,
      expenses: 0,
      agreements: 0,
      loansTouched: 0,
      notifications: 0,
      fundingRequests: 0,
      receivedDocuments: 0,
      tasksAssignedToMe: 0,
      withdrawalRequests: 0
    },
    filePreviewModal: { isOpen: false, url: null, filename: null },
    taskModal: { isOpen: false, taskId: null, tabName: 'subTask' },
    loansModal: {
      borrower: {
        firstName: null,
        lastName: null,
        coFirstName: null,
        coLastName: null,
        creditScore: null,
        email: null,
        coEmail: null,
      },
      purpose: 'Refinance',
      productType: null,
      productAndTerm: null,
      amount: null,
      number: null,
      payingOffMortgagesNumber: null,
      rate: null,
      miStatus: null,
      lender: null,
      lenderType: null,
      listingAgents: [],
      buyingAgents: [],
      preferredTitleCompany: null,
      reverseMortgageType: null,
      LOofRecord: null,
      reverseMortgagePurpose: null,
      isHighCostMortgage: false,
      realtor: null,
      lienPosition: null,
      waivedStatus: null,
      daysOfInterest: null,
      cashoutAmount: null,
      appraisalPayer: null,
      appraisalNote: null,
      aus: null,
      isHeroLoan: false,
      isSubordination: false,
      isSolarPanels: false,
      eVerifyIncome: false,
      antiSteeringLowestRateAndFee: null,
      antiSteeringHighestRateAndFee: null,
      otherNotes: null,
      feesForBorrower: null,
      vaExempt: null,
      compensationType: null,
      waivingUnderwriting: null,
      insuranceMonthsToCollect: null,
      taxesMonthsToCollect: null,
      accept: false,
      processor: null,
      enhancedProcessing: null,
      rateType: null,
      vesting: null,
      title: null,
      isNonBorrowingSpouse: null,
      shortToClose: null,
      propertyState: null,
      isReferral: null,
      vaType: null,
      vaUseType: null,
      state: {
        agents: []
      },
      occupancy: null,
      propertyType: null,
      source: null,
      sourceOtherDescription: null,
      alreadyHasCreditLogin: true,
      creditLoginUserName: null,
      creditLoginPassword: null,
      lendingpadNumber: null,
      estimatedClosingDate: null,
      hoaName: null,
      hoaPhone: null,
      hoaEmail: null,
      isFirstTimeHomeBuyer: false,
    },
    timerTracker: {
      currentSessionTime: null,
      todaySeconds: null,
      weekSeconds: null,
      isTracking: false
    },
    statusNotification: { isOpen: null, msg: null, status: null, delay: 2000 },
    isNewDesign: false,
    precessedFundingIds: [],
    houseFlyer: {
      images: null,
      selectedAgent: null,
      loanDetails: null,
      editor: null,
      realtor: null,
      disclaimer: null
    },
    businessCard: {
      name: '',
      position: '',
      nmls: '',
      phone: '',
      email: '',
      address: '',
      stateName: '',
      dre: '',
      activeTab: '',
      isQrCard: false,
      customURL: '',
      urlType: ''
    },
    announcement: {
      id: 0,
      isActive: false,
      message: null,
    },
  },
  mutations: {
    setUser(state, user) {
      state.user.id = user.id
      state.user.firstName = user.firstName
      state.user.lastName = user.lastName
      state.user.email = user.email
      state.user.photo = user.photo
      state.user.token = user.token
      state.user.roles = user.roles
      state.user.position = user.position
      state.user.nmls = user.nmls
      state.user.phone = user.phone
      state.user.dre = user.dre
      state.user.isAgreeToTerms = user.isAgreeToTerms
      state.user.isNewCRM = user.isNewCRM
      state.user.isFirstTime = user.isFirstLogin
    },
    updateNewCRM(state, value) {
      state.user.isNewCRM = value
    },
    setUserPhoto(state, photo) {
      state.user.photo = photo
    },
    setCounters(state, counters) {
      state.counters.loans = counters.loans
      state.counters.tickets = counters.tickets
      state.counters.expenses = counters.expenses
      state.counters.agreements = counters.agreements
      state.counters.loansTouched = counters.loansTouched
      state.counters.notifications = counters.notifications
      state.counters.fundingRequests = counters.fundingRequests
      state.counters.receivedDocuments = counters.receivedDocuments
      state.counters.tasksAssignedToMe = counters.tasksAssignedToMe
      state.counters.withdrawalRequests = counters.withdrawalRequests
    },
    openFilePreviewModal(state, params) {
      state.filePreviewModal.isOpen = true
      state.filePreviewModal.url = params.url
      state.filePreviewModal.filename = params.filename
    },
    closeFilePreviewModal(state) {
      state.filePreviewModal.isOpen = false
      state.filePreviewModal.url = null
      state.filePreviewModal.filename = null
    },
    toggleTaskModal(state, params) {
      let openStatus = state.taskModal.isOpen
      state.taskModal.isOpen = !openStatus;
      state.taskModal.taskId = params.taskId;
      state.taskModal.tabName = params.tabName
    },
    closeTaskModal(state) {
      state.taskModal.isOpen = false;
      state.taskModal.taskId = null;
    },
    setToken(state, token) {
      state.user.token = token
    },
    saveLoan(state, value) {
      if (value === false) {
        state.loansModal = {
          borrower: {
            firstName: null,
            lastName: null,
            coFirstName: null,
            coLastName: null,
            creditScore: null,
            email: null,
          },
          purpose: 'Refinance',
          productType: null,
          productAndTerm: null,
          amount: null,
          number: null,
          payingOffMortgagesNumber: null,
          rate: null,
          miStatus: null,
          lender: null,
          preferredTitleCompany: null,
          lenderType: null,
          reverseMortgageType: null,
          LOofRecord: null,
          reverseMortgagePurpose: null,
          isHighCostMortgage: false,
          realtor: null,
          lienPosition: null,
          waivedStatus: null,
          daysOfInterest: null,
          cashoutAmount: null,
          appraisalPayer: null,
          appraisalNote: null,
          aus: null,
          isHeroLoan: false,
          isSubordination: false,
          isSolarPanels: false,
          eVerifyIncome: false,
          antiSteeringLowestRateAndFee: null,
          antiSteeringHighestRateAndFee: null,
          otherNotes: null,
          feesForBorrower: null,
          vaExempt: null,
          compensationType: null,
          waivingUnderwriting: null,
          insuranceMonthsToCollect: null,
          taxesMonthsToCollect: null,
          accept: false,
          processor: null,
          enhancedProcessing: null,
          rateType: null,
          title: null,
          vesting: null,
          isNonBorrowingSpouse: null,
          shortToClose: null,
          propertyState: null,
          isReferral: null,
          state: {
            agents: []
          },
          occupancy: null,
          propertyType: null,
          source: null,
          sourceOtherDescription: null,
          alreadyHasCreditLogin: true,
          creditLoginUserName: null,
          creditLoginPassword: null,
          lendingpadNumber: null,
          estimatedClosingDate: null
        };
      } else {
        state.loansModal = value;
      }
    },
    setIsNewDesign(state, isNewDesign) {
      state.isNewDesign = isNewDesign
    },
    updateTimer(state, value) {
      state.timerTracker = value;
    },
    openStatusNotification(state, params) {
      state.statusNotification.isOpen = true;
      state.statusNotification.msg = params.msg;
      state.statusNotification.status = params.status;
      state.statusNotification.delay = params.delay;
    },
    closeStatusNotification(state) {
      state.statusNotification.isOpen = false;
    },
    logout() {
      this.setUser({})
    },
    setAutoProcessedFundingIds(state, ids) {
      state.precessedFundingIds = ids
    },
    saveFlyer(state, { editor, loanDetails, selectedAgent, images, realtor, disclaimer }) {
      state.houseFlyer.editor = editor
      state.houseFlyer.loanDetails = loanDetails
      state.houseFlyer.selectedAgent = selectedAgent
      state.houseFlyer.images = images
      state.houseFlyer.realtor = realtor
      state.houseFlyer.disclaimer = disclaimer
    },
    saveBusinessCard(state, params) {
      state.businessCard.name = params.name
      state.businessCard.position = params.position
      state.businessCard.nmls = params.nmls
      state.businessCard.phone = params.phone
      state.businessCard.email = params.email
      state.businessCard.address = params.address
      state.businessCard.stateName = params.stateName
      state.businessCard.dre = params.dre
      state.businessCard.activeTab = params.activeTab
      state.businessCard.isQrCard = params.isQrCard
      state.businessCard.customURL = params.customURL
      state.businessCard.urlType = params.urlType
    },
    openAnnouncementModal(state, announcement) {
      state.announcement.id = announcement.id;
      state.announcement.message = announcement.message;
      state.announcement.isActive = true;
    },
    closeAnnouncementModal(state) {
      state.announcement.isActive = false;
      state.announcement.id = 0;
      state.announcement.message = null;
    },
    updateIsFirstTime(state) {
      state.user.isFirstTime = false;
    },
    setFlyerId(state, value) {
      state.user.flyerId = value;
    }
  },
  actions: {
    SET_USER(context, user) {
      context.commit('setUser', user)
    },
    SET_USER_PHOTO(context, photo) {
      context.commit('setUserPhoto', photo)
    },
    SET_COUNTERS(context, counters) {
      context.commit('setCounters', counters)
    },
    SET_TOKEN(context, token) {
      context.commit('setToken', token)
    },
    LOGOUT(context) {
      context.commit('setUser', {})
      context.commit('setToken', null)
    },
    OPEN_FILE_PREVIEW_MODAL(context, params) {
      context.commit('openFilePreviewModal', params)
    },
    CLOSE_FILE_PREVIEW_MODAL(context) {
      context.commit('closeFilePreviewModal')
    },
    TOGGLE_TASK_MODAL(context, params) {
      context.commit('toggleTaskModal', params);
    },
    CLOSE_TASK_MODAL(context) {
      context.commit('closeTaskModal');
    },
    OPEN_STATUS_NOTIFICATION(context, params) {
      context.commit('openStatusNotification', params);
    },
    CLOSE_STATUS_NOTIFICATION(context) {
      context.commit('closeStatusNotification');
    },
    SET_IS_NEW_DESIGN(context, isNewDesign) {
      context.commit('setIsNewDesign', isNewDesign);
    },
    SET_AUTO_PROCESSED_FUNDING_IDS(context, ids) {
      context.commit('setAutoProcessedFundingIds', ids);
    },
    OPEN_ANNOUNCEMENT_MODAL(context, announcement) {
      context.commit('openAnnouncementModal', announcement);
    },
    CLOSE_ANNOUNCEMENT_MODAL(context) {
      context.commit('closeAnnouncementModal');
    },
    UPDATE_NEW_CRM(context, val) {
      context.commit('updateNewCRM', val);
    },
    UPDATE_IS_FIRST_TIME(context) {
      context.commit('updateIsFirstTime')
    },
    SET_FLYER_ID(context, value) {
      context.commit('setFlyerId', value)
    }
  },
  getters: {
    getFullName(state) {
      return `${state.user.firstName} ${state.user.lastName}`
    },
    getEmail(state) {
      return state.user.email
    },
    isNewCrm(state) {
      return state.user.isNewCRM
    },
    getNmls(state) {
      return state.user.nmls
    },
    getPhone(state) {
      return state.user.phone
    },
    getUserId(state) {
      return state.user.id
    },
    getPhoto(state) {
      return state.user.photo
    },
    getToken(state) {
      return state.user.token
    },
    getRoles(state) {
      return state.user.roles
    },
    getPosition(state) {
      return state.user.position
    },
    getDre(state) {
      return state.user.dre
    },
    getCounters(state) {
      return state.counters
    },
    getLoanModal(state) {
      return state.loansModal;
    },
    openTaskModal(state) {
      return state.taskModal.isOpen
    },
    getIsNewDesign(state) {
      return state.isNewDesign;
    },
    getAutoPrecessedFundingIds(state) {
      return state.precessedFundingIds
    },
    getFlyerData(state) {
      if (state.houseFlyer.editor === null || state.houseFlyer.images === null ||
        state.houseFlyer.realtor === null || state.houseFlyer.loanDetails === null || state.houseFlyer.selectedAgent === null) {
        return false;
      } else {
        return state.houseFlyer
      }
    },
    getBusinessCardData(state) {
      return state.businessCard
    },
    getFlyerId(state) {
      return state.user.flyerId
    }
  },
  modules: {
    appConfig,
  }
})
