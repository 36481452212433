<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">
          Expenses
        </h3>
      </div>
      <div class="page-header-buttons">
        <button type="button" class="btn btn-primary" @click="modals.newExpense = true">
          New Expense
        </button>
        <button class="btn btn-outline-dark ms-2" @click="modals.addNewRecurring = true">
          Add Recurring Expense
        </button>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <add-expenses-modal v-if="modals.newExpense === true"
                          @openVendor="modals.addVendor = true"
                          @close="modals.newExpense = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <add-vendor-modal v-if="modals.addVendor === true"
                        @close="modals.addVendor = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <add-new-recurring-modal v-if="modals.addNewRecurring === true"
                               @close="modals.addNewRecurring = false" />
    </transition>
  </div>
</template>

<script>
import AddExpensesModal from "../../accounts/modals/AddExpensesModal";
import AddVendorModal from "../../accounts/modals/AddVendorModal";
import AddNewRecurringModal from "../modals/AddNewRecurringModal";

export default {
  name: 'ExpenseHeader',
  components: { AddNewRecurringModal, AddVendorModal, AddExpensesModal },
  data() {
   return {
     modals: {
       newExpense: false,
       addVendor: false,
       addNewRecurring: false
     }
   }
  }
}
</script>
