import FundingRequests from "../views/fundingRequests/FundingRequests";
import Expense from "../views/expense/Expense";
import store from "@/store";

let roles = store.getters.getRoles
if (typeof roles === 'object' && !Array.isArray(roles)) {
  roles = Object.values(roles)
}

let isCEO = roles.includes('ROLE_CEO')
let isCEOAssistant = roles.includes('ROLE_CEO_ASSISTANT')
let isBookkeeper = roles.includes('ROLE_BOOKKEEPER')
let isSoftDeleted = roles.includes('ROLE_SOFT_DELETED');
let isAuditTrail = roles.includes('ROLE_AUDIT_TRAIL');
let isBranchManager = roles.includes('ROLE_BRANCH_MANAGER');

let routes = [
  // auth
  {
    path: '/login',
    name: 'auth.login',
    component: () => import('../views/auth/Login.vue'),
    meta: { title: 'Login' }
  },
  {
    path: '/login-as/:currentUserToken/:id',
    name: 'auth.login-as',
    component: () => import('../views/auth/LoginAs.vue'),
    meta: { title: 'Login-As' }
  },
  {
    path: '/reset-pass',
    name: 'reset_pass',
    component: () => import('../views/auth/PasswordReset'),
    meta: { title: 'Reset Password' }
  },
  // end auth
  // credit auth
  {
    path: '/credit_auth/submit/:uuid',
    name: 'credit_auth_submit_document',
    component: () => import('../views/creditAuth/CreditReportSubmitDocument'),
    meta: { title: 'Submit Credit Document' }
  },
  {
    path: '/page-not-found',
    name: 'page_not_found',
    component: () => import('../views/404/404'),
    meta: { title: 'Page not found' },
  },
  {
    path: '/e-sign/:uuid',
    name: 'e-sign',
    component: () => import('../views/eSign/ESign'),
    meta: { title: 'CMC Credit Auth + Privacy Notice + Receipt' },
  },
  {
    path: '/rate-quote-funnel/:id',
    name: 'rate_quote_funnel',
    component: () => import('../views/rateQuoteFunnel/RateQuoteFunnel'),
    meta: { title: 'Rate Quote Funnel' },
  },
  {
    path: '/',
    component: () => import('../views/layouts/Index.vue'),
    children: [
      // -------------------------------
      // Main links
      // -------------------------------
      {
        path: '/',
        name: 'dashboard',
        component: () => import('../views/dashboard/Dashboard.vue'),
        meta: { title: 'Dashboard' }
      },
      {
        path: '/accounts',
        name: 'accounts',
        component: () => import('../views/accounts/Accounts.vue'),
        meta: { title: 'Accounts' }
      },
      {
        path: '/loans',
        name: 'loans',
        component: () => import('../views/loans/Loans.vue'),
        meta: { title: 'Loans' }
      },
      {
        // @todo: check route, maybe should be deleted
        path: '/loans/closing',
        name: 'loan_closing',
        component: () => import('../views/loans/LoansClosing.vue'),
        meta: { title: 'Loans' }
      },
      {
        // @todo: check route, maybe should be deleted
        path: '/loans/funded',
        name: 'loan_funded',
        component: () => import('../views/loans/LoansFunded.vue'),
        meta: { title: 'Loans' }
      },
      {
        // @todo: check route, maybe should be deleted
        path: '/loans/declined',
        name: 'loan_declined',
        component: () => import('../views/loans/LoansDeclined.vue'),
        meta: { title: 'Loans' }
      },
      {
        path: '/lenders',
        name: 'lenders',
        component: () => import('../views/lenders/Lenders.vue'),
        meta: { title: 'Lenders' }
      },
      {
        path: '/escrow_companies',
        name: 'escrow_companies',
        component: () => import('../views/escrowCompanies/EscrowCompanies.vue'),
        meta: { title: 'Escrow companies' }
      },
      {
        path: '/funding_requests',
        component: () => import('../views/fundingRequests/FundingRequests.vue'),
        children: [
          {
            path: '/funding_requests',
            name: 'funding_requests_pending',
            meta: { title: 'Funding requests' },
            components: {
              default: FundingRequests,
              funding_request: () => import('../views/fundingRequests/FundingRequestsPending'),
            }
          },
          {
            path: '/funding_requests/proceed',
            name: 'funding_requests_proceed',
            meta: { title: 'Funding requests' },
            components: {
              default: FundingRequests,
              funding_request: () => import('../views/fundingRequests/FundingRequestProcessed'),
            }
          },
          {
            path: '/funding_requests/deleted',
            name: 'funding_requests_deleted',
            meta: { title: 'Funding requests' },
            components: {
              default: FundingRequests,
              funding_request: () => import('../views/fundingRequests/FundingRequestDeleted'),
            }
          },
          {
            path: '/funding_requests/auto-processed',
            name: 'funding_requests_auto_processed',
            meta: { title: 'Funding requests' },
            components: {
              default: FundingRequests,
              funding_request: () => import('../views/fundingRequests/FundingRequestAutoProcessed'),
            }
          },
          {
            path: '/funding_requests/branch',
            name: 'funding_requests_branch',
            meta: { title: 'Funding requests' },
            components: {
              default: FundingRequests,
              funding_request: () => import('../views/fundingRequests/FundingRequestBranch'),
            }
          },
        ]
      },
      {
        path: '/quote-requests',
        name: 'quote_requests',
        component: () => import('../views/quoteRequests/Index.vue'),
        meta: { title: 'Quote Requests' }
      },
      {
        path: '/expense',
        component: () => import('../views/expense/Expense.vue'),
        children: [
          {
            path: '/expense',
            name: 'expense_pending',
            meta: { title: 'Expense' },
            components: {
              default: Expense,
              expense: () => import('../views/expense/ExpensePending'),
            }
          },
          {
            path: '/expense/processed',
            name: 'expense_processed',
            meta: { title: 'Expense' },
            components: {
              default: Expense,
              expense: () => import('../views/expense/ExpenseProcessed'),
            }
          },
          {
            path: '/expense/deleted',
            name: 'expense_deleted',
            meta: { title: 'Expense' },
            components: {
              default: Expense,
              expense: () => import('../views/expense/ExpenseDeleted'),
            }
          },
          {
            path: '/expense/recurring',
            name: 'expense_recurring',
            meta: { title: 'Expense' },
            components: {
              default: Expense,
              expense: () => import('../views/expense/ExpenseRecurring'),
            }
          },
        ]
      },
      {
        path: '/correspondent-pricing',
        name: 'corr_pricing',
        component: () => import('../views/corrPricer/CorrPricer.vue'),
        meta: { title: 'Correspondent Pricing' }
      },
      {
        path: '/correspondent-pricing/config',
        name: 'corr_pricing_config',
        component: () => import('../views/corrPricer/CorrPricerConfig.vue'),
        meta: { title: 'Correspondent Pricing Config' },
        beforeEnter: (to, from, next) => {
          if (isCEO || isCEOAssistant) {
            next()
          } else {
            next({ name: 'corr_pricing' })
          }
        }
      },
      {
        path: '/credit-auth',
        name: 'credit_auth',
        component: () => import('../views/creditAuth/CreditAuth.vue'),
        meta: { title: 'Credit Auth Pending' }
      },
      {
        path: '/credit-auth/new',
        name: 'new_credit_auth',
        component: () => import('../views/creditAuth/CreditAuthRequest/CreditAuthNewRequest'),
        meta: { title: 'New Credit Auth Request' }
      },
      {
        path: '/credit-auth/signed/:uuid',
        name: 'signed_credit_auth',
        component: () => import('../views/creditAuth/CreditAuthRequest/CreditAuthSignedDocument'),
        meta: { title: 'Signed Document' }
      },
      {
        path: '/credit-auth/details/:uuid',
        name: 'credit_auth_details',
        component: () => import('../views/creditAuth/CreditAuthRequest/CreditRequestAuthDetails'),
        meta: { title: 'Request Credit Auth' }
      },
      {
        path: '/tasks',
        name: 'tasks',
        component: () => import('../views/tasks/Tasks.vue'),
        meta: { title: 'Tasks' }
      },
      {
        path: '/task/presets',
        name: 'task_presets',
        component: () => import('../views/taskPresets/TaskPresets.vue'),
        meta: { title: 'Task Presets' }
      },
      {
        path: '/borrower-gift',
        name: 'borrower_gift',
        component: () => import('../views/borrowerGift/BorrowerGift.vue'),
        meta: { title: 'Borrower gift' }
      },
      {
        path: '/rankings',
        name: 'rankings',
        component: () => import('../views/rankings/Rankings.vue'),
        meta: { title: 'Rankings' }
      },
      {
        path: '/secure-inbox',
        name: 'secure_inbox',
        component: () => import('../views/secureInbox/SecureInbox.vue'),
        meta: { title: 'Secure Inbox' }
      },
      {
        path: '/resource-center',
        name: 'resource_center',
        component: () => import('../views/resourceCenter/ResourceCenter'),
        meta: { title: 'Resource Center' }
      },
      {
        path: '/resource-center/ad-wizard',
        name: 'ad_wizard',
        component: () => import('../views/adWizard/AdWizard.vue'),
        meta: { title: 'Ad Wizard' }
      },
      {
        path: '/resource-center/business-card',
        name: 'business_card_generator',
        component: () => import('../views/businessCard/BusinessCardGenerator'),
        meta: { title: 'Business Card' }
      },
      {
        path: '/resource-center/house-flyer/editor',
        name: 'house_flyer_editor',
        component: () => import('../views/openHouseFlyer/OpenHouseFlyerEditor'),
        meta: { title: 'Open House Flyer' }
      },
      {
        path: '/resource-center/house-flyer/preview',
        name: 'house_flyer_preview',
        component: () => import('../views/openHouseFlyer/OpenHouseFlyerPreview'),
        meta: { title: 'Open House Flyer Preview' }
      },
      {
        path: '/resource-center/homebuyer-guide',
        name: 'homebuyer_guide',
        component: () => import('../views/resourceCenter/HomebuyerGuide'),
        meta: { title: 'Home Buyer Guide' }
      },
      {
        path: '/resource-center/yard-sign',
        name: 'yard_sign',
        component: () => import('../views/yardSign/YardSign'),
        meta: { title: 'Yard Sign' }
      },
      {
        path: '/resolution-center',
        name: 'resolution_center',
        component: () => import('../views/resolutionCenter/ResolutionCenter'),
        meta: { title: 'Resolution Center' }
      },
      {
        path: '/resolution-center/:id',
        name: 'ticket_view',
        component: () => import('../views/resolutionCenter/ResolutionCenterTicket'),
        meta: { title: 'Ticket View' }
      },
      {
        path: '/withdrawal-requests',
        name: 'withdrawal',
        component: () => import('../views/withdrawalRequests/WithdrawalRequests.vue'),
        meta: { title: 'Withdrawal Requests' }
      },
      {
        path: '/mailer-approval-form',
        name: 'mailer_approval_form',
        component: () => import('../views/MailerApprovalForm'),
        meta: { title: 'Mailer Approval Form' }
      },
      {
        path: '/touch-screen',
        name: 'touch_screen',
        component: () => import('../views/touchScreen/TouchScreen'),
        meta: { title: 'Touch Screen' }
      },

      // -------------------------------
      // Admin links
      // -------------------------------
      {
        path: '/users',
        name: 'users',
        component: () => import('../views/users/Users.vue'),
        meta: { title: 'Users' }
      },
      {
        path: '/user/:id',
        name: 'users_details',
        component: () => import('../views/users/UserDetails.vue'),
        meta: { title: 'Users Details' }
      },
      {
        path: '/credit_report',
        name: 'credit_report',
        component: () => import('../views/creditReport/CreditReport.vue'),
        meta: { title: 'Credit report billing tool' }
      },
      {
        path: '/lendingtree',
        name: 'lendingtree',
        component: () => import('../views/lendingtree/Lendingtree.vue'),
        meta: { title: 'Lendingtree' }
      },
      {
        path: '/expense-category',
        name: 'expenseCategory',
        component: () => import('../views/expenseCategories/ExpenseCategory.vue'),
        meta: { title: 'Expenses Categories' }
      },
      {
        path: '/agreement',
        name: 'agreement',
        component: () => import('../views/agreement/Agreement.vue'),
        meta: { title: 'Agreements' }
      },
      {
        path: '/branch',
        name: 'branch',
        component: () => import('../views/branch/Branch.vue'),
        meta: { title: 'Branches' }
      },
      {
        path: '/announcements',
        name: 'announcements',
        component: () => import('../views/announcements/Announcements.vue'),
        meta: { title: 'Announcements' }
      },
      {
        path: '/timer',
        name: 'timer',
        component: () => import('../views/timer/Timer'),
        meta: { title: 'Timer' }
      },
      {
        path: '/positions',
        name: 'positions',
        component: () => import('../views/positions/Positions.vue'),
        meta: { title: 'Positions' }
      },
      {
        path: '/vendor',
        name: 'vendor',
        component: () => import('../views/vendor/Vendor.vue'),
        meta: { title: 'Expense Vendor' }
      },
      {
        path: '/gift/manage',
        name: 'gift_manage',
        component: () => import('../views/giftManage/GiftManage.vue'),
        meta: { title: 'Gifts' }
      },
      {
        path: '/reports',
        name: 'reports',
        component: () => import('../views/reports/Reports.vue'),
        meta: { title: 'Reports' },
        beforeEnter: (to, from, next) => {
          if (isCEO || isCEOAssistant || isBranchManager) {
            next()
          } else {
            next({ name: 'dashboard' })
          }
        }
      },
      {
        path: '/reports/details',
        name: 'reports_details',
        component: () => import('../views/reports/ReportDetails.vue'),
        meta: { title: 'Reports Company' },
        beforeEnter: (to, from, next) => {
          if (isCEO || isCEOAssistant || isBranchManager) {
            next()
          } else {
            next({ name: 'dashboard' })
          }
        }
      },
      {
        path: '/reports/new',
        name: 'new_report',
        component: () => import('../views/reports/CreateReport.vue'),
        meta: { title: 'Reports Company' },
        beforeEnter: (to, from, next) => {
          if (isCEO || isCEOAssistant || isBranchManager) {
            next()
          } else {
            next({ name: 'dashboard' })
          }
        }
      },
      {
        path: '/reports/edit/:id',
        name: 'edit_report',
        component: () => import ('../views/reports/CreateReport.vue'),
        meta: {title: 'Reports Company'},
        beforeEnter: (to, from, next) => {
          if (isCEO || isCEOAssistant || isBranchManager) {
            next()
          } else {
            next({ name: 'dashboard' })
          }
        }
      },
      {
        path: '/mlo-landing',
        name: 'mlo_landing',
        component: () => import('../views/landing/preview/MloLanding.vue'),
        meta: { title: 'Mlo Landing' }
      },
      {
        path: '/config/landing',
        name: 'config_landing',
        component: () => import('../views/landing/Config.vue'),
        meta: { title: 'Config Landing' }
      },
      {
        path: '/email-logs',
        name: 'email_logs',
        component: () => import('../views/emailLogs/Index.vue'),
        meta: { title: 'Email Logs' }
      },
      {
        path: '/bank-rate-lead-tool',
        name: 'bank_rate_lead_tool',
        component: () => import('../views/bankrateLead/BankrateLeadTool'),
        meta: { title: 'Bankrate Lead Tool' },
        beforeEnter: (to, from, next) => {
          if (isCEOAssistant || isBookkeeper) {
            next()
          } else {
            next({ name: 'dashboard' })
          }
        }
      },
      {
        path: '/bank-rate-lead-tool-sync',
        name: 'bank_rate_lead_tool_sync',
        component: () => import ('../views/bankrateLead/BankrateBillingTool.vue'),
        meta: {title: 'Bankrate Lead Tool'},
        beforeEnter: (to, from, next) => {
          if (isCEOAssistant || isBookkeeper) {
            next()
          } else {
            next({name: 'dashboard'})
          }
        }
      },
      {
        path: '/resource-center/pre-approval-letters',
        name: 'pre_approval_letters',
        component: () => import('../views/preApprovalLetter/PreApprovalLetters'),
        meta: { title: 'Pre-Approval Letters' }
      },
      {
        path: '/resource-center/pre-approval-letters/create',
        name: 'pre_approval_letter_create',
        component: () => import('../views/preApprovalLetter/PreApprovalLetterCreate'),
        meta: { title: 'New Pre-Approval Letter' }
      },
      {
        path: '/resource-center/pre-approval-letters/:id',
        name: 'pre_approval_letter_single',
        component: () => import('../views/preApprovalLetter/PreApprovalLetterSingle'),
        meta: { title: 'Pre-Approval Letter' }
      },
      {
        path: '/resource-center/training-videos',
        name: 'training_videos',
        component: () => import('../views/trainingVideos/single/TrainingVideosSingle'),
        meta: { title: 'Training Videos' }
      },
      {
        path: '/resource-center/training-videos/config',
        name: 'training_videos_config',
        component: () => import('../views/trainingVideos/editor/TrainingVideosEditor'),
        meta: { title: 'Training Videos Configuration' },
        beforeEnter: (to, from, next) => {
          if (isCEO || isCEOAssistant) {
            next()
          } else {
            next({ name: 'training_videos' })
          }
        }
      },
      {
        path: '/resource-center/marketing-resource',
        name: 'marketing_resource',
        component: () => import('../views/marketingCenter/Mlo/index'),
        meta: { title: 'Marketing Resource' }
      },
      {
        path: '/resource-center/marketing-resource/admin',
        name: 'marketing_resource_admin',
        component: () => import('../views/marketingCenter/Admin/index'),
        meta: { title: 'Marketing Resource Admin' },
        beforeEnter: (to, from, next) => {
          if (isCEO || isCEOAssistant) {
            next()
          } else {
            next({ name: 'marketing_resource' })
          }
        }
      },
      {
        path: '/resource-center/marketing-resource/assets_creation',
        name: 'assets_creation',
        component: () => import('../views/marketingCenter/Mlo/AssetsCreation'),
        meta: { title: 'Assets Creation' }
      },
      {
        path: '/lower-my-bills-tool',
        name: 'lower_my_bills_tool',
        meta: { title: 'LowerMyBills Billing Tool' },
        component: () => import('../views/lowerMyBills/LowerMyBillsTool'),
        beforeEnter: (to, from, next) => {
          if (isCEO || isCEOAssistant) {
            next()
          } else {
            next({ name: 'dashboard' })
          }
        }
      },
      {
        path: '/brands',
        name: 'brands',
        component: () => import('../views/resourceCenter/brands/Brands'),
        meta: { title: 'Brands' }
      },
      {
        path: '/brands/:name',
        name: 'brands_single',
        component: () => import('../views/resourceCenter/brands/SingleBrands'),
        meta: { title: 'Single Brands' }
      },
      {
        path: '/activity',
        name: 'activity',
        component: () => import('../views/activity/Activity'),
        meta: { title: 'Activity' },
        beforeEnter: (to, from, next) => {
          if (isCEO || isCEOAssistant || isAuditTrail) {
            next()
          } else {
            next({ name: 'dashboard' })
          }
        }
      },
      {
        path: '/retread',
        name: 'retread',
        component: () => import('../views/retread/Retread'),
        meta: { title: 'Retread' }
      },
      {
        path: '/company-directory',
        name: 'team_directory',
        component: () => import('../views/teamDirectory/TeamDirectory'),
        meta: { title: 'Team Directory' }
      },
      {
        path: '/states',
        name: 'states',
        component: () => import('../views/states/States'),
        meta: { title: 'States' }
      },
      {
        path: '/w2-commissions',
        name: 'w2_commissions',
        component: () => import('../views/w2Commissions/W2Commissions'),
        meta: { title: 'W2 Commissions' }
      },
      {
        path: '/branches',
        name: 'branches',
        component: () => import('../views/publicBranches/Branches'),
        meta: { title: 'Branches' }
      },
      {
        path: '/submit-feedback',
        name: 'submit_confidential_feedback',
        component: () => import('../views/submitConfidentialFeedback/index'),
        meta: { title: 'Submit Confidential Feedback' }
      },
    ]
  },
]

if (isSoftDeleted) {
  routes = [
    // auth
    {
      path: '/login',
      name: 'auth.login',
      component: () => import ('../views/auth/Login.vue'),
      meta: {title: 'Login'},
    },
    {
      path: '/reset-pass',
      name: 'reset_pass',
      component: () => import ('../views/auth/PasswordReset'),
      meta: {title: 'Reset Password'}
    },
    // end auth
    // credit auth
    {
      path: '/page-not-found',
      name: 'page_not_found',
      component: () => import ('../views/404/404'),
      meta: {title: 'Page not found'},
    },
    {
      path: '/',
      component: () => import ('../views/layouts/Index.vue'),
      children: [
        // -------------------------------
        // Main links
        // -------------------------------
        {
          path: '/funding_requests',
          component: () => import ('../views/fundingRequests/FundingRequests.vue'),
          children: [
            {
              path: '/funding_requests',
              name: 'funding_requests_pending',
              meta: {title: 'Funding requests'},
              components: {
                default: FundingRequests,
                funding_request: () => import ('../views/fundingRequests/FundingRequestsPending'),
              }
            },
            {
              path: '/funding_requests/proceed',
              name: 'funding_requests_proceed',
              meta: {title: 'Funding requests'},
              components: {
                default: FundingRequests,
                funding_request: () => import ('../views/fundingRequests/FundingRequestProcessed'),
              }
            },
            {
              path: '/funding_requests/deleted',
              name: 'funding_requests_deleted',
              meta: {title: 'Funding requests'},
              components: {
                default: FundingRequests,
                funding_request: () => import ('../views/fundingRequests/FundingRequestDeleted'),
              }
            },
            {
              path: '/funding_requests/auto-processed',
              name: 'funding_requests_auto_processed',
              meta: {title: 'Funding requests'},
              components: {
                default: FundingRequests,
                funding_request: () => import ('../views/fundingRequests/FundingRequestAutoProcessed'),
              }
            },
            {
              path: '/funding_requests/branch',
              name: 'funding_requests_branch',
              meta: {title: 'Funding requests'},
              components: {
                default: FundingRequests,
                funding_request: () => import ('../views/fundingRequests/FundingRequestBranch'),
              }
            },
          ]
        },
      ]
    },
  ]
}

export default routes
