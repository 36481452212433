import baseAxios from 'axios'
import store from './store/index'
import router from "./router";
import {getBackendUrl} from "@/utils/backendUrl";

let apiUrl = getBackendUrl()

const config = {
  baseURL: apiUrl,
  timeout: 50000,
  headers: {
    'Content-Type': 'application/json',
    'api-token': store.getters.getToken ?? ''
  }
}

export const axios = baseAxios.create(config)

axios.interceptors.response.use(
  response => { return response },
  error => {
    if (401 === error.response.status) {
      store.dispatch('LOGOUT');
      router.push({ name: 'auth.login' })
    }
    // if (403 === error.response.status) { router.push({name: 'dashboard'}); }
    // if (404 === error.response.status) { store.dispatch('logout'); }
    // if (500 === error.response.status) { store.dispatch('logout'); }

    return Promise.reject(error);
  }
);
