<template>
  <div id="app">
    <vue-page-transition>
      <router-view />
    </vue-page-transition>
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    $route(to) {
      document.title = to.meta.title + " - Vision" || "Vision";
    },
  },
  methods: {
    getUserData() {
      if (!this.$store.getters.getToken) {
        return;
      }

      this.$http
        .get("/api/v1/user")
        .then((response) => {
          this.$store.dispatch("SET_USER", response.data.user);
          this.$store.dispatch("SET_COUNTERS", response.data.counter);
          this.$store.dispatch("SET_IS_NEW_DESIGN", response.data.isNewDesign);
        })
        .catch();
    },
  },
  mounted() {
    this.$root.$on("updateUser", () => {
      this.getUserData();
    });
  },
  created() {
    this.getUserData();
  },
};
</script>
