import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from './store/index'
import { axios } from '@/axios'
import VuePageTransition from 'vue-page-transition'
import './mixin'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VueTippy, { TippyComponent } from "vue-tippy";
import CKEditor from '@ckeditor/ckeditor5-vue';
import vAvatar from '@amaury-tobias/v-avatar';
import vClickOutside from 'v-click-outside';
import InputFilled from '@/directives/InputFilled';
import TextHighlight from 'vue-text-highlight';
import VueLazyload from 'vue-lazyload'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueCookie from 'vue-cookie';

import VueMask from 'v-mask';
import MyUpload from 'vue-image-crop-upload';
import './assets/scss/main.scss'
import 'cropperjs/dist/cropper.css';

// Vue Tippy CSS start
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";
// Vue Tippy CSS end

Vue.config.productionTip = false
Vue.prototype.$store = store
Vue.prototype.$http = axios

Vue.use(VueTippy);
Vue.use(VuePageTransition);
Vue.use(CKEditor);
Vue.use(vClickOutside);
Vue.use(VueLazyload, {
  lazyComponent: true
});
Vue.component("my-upload", MyUpload);
Vue.component("tippy", TippyComponent);
Vue.component('text-highlight', TextHighlight);
Vue.directive('input-filled', InputFilled);
Vue.use(VuePageTransition);
Vue.use(vAvatar);
Vue.use(VueMask);
Vue.config.errorHandler = function (error, vm, info) {
  console.log(error, vm, info);
}

Vue.use(VueCookie);
Vue.filter('money', function (value) {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});
Vue.filter('toUSANumber', function (phone) {
  if (phone) {
    return phone.replace(/[^0-9]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  return 'N/A';
});

let sentryDSN = ''
if (process.env.BOT_PRICER_DOWNLOAD === 'true') {
  sentryDSN = 'https://96ceec1f4705451196ba1b236477ed0a@o504045.ingest.sentry.io/6126682'
}

Sentry.init({
  Vue,
  dsn: sentryDSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["vision-crm.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
