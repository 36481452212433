<template>
  <div>
    <expense-header />
    <div class="page-tabs d-flex justify-content-between">
      <ul class="tab-list">
        <li :class="{ 'active': page === 'expense_pending' }" @click="goto('expense_pending')">
            Pending
        </li>
        <li :class="{ 'active': page === 'expense_processed' }" @click="goto('expense_processed')">
            Processed
        </li>
        <li :class="{ 'active': page === 'expense_deleted' }" @click="goto('expense_deleted')">
            Deleted
        </li>
        <li :class="{ 'active': page === 'expense_recurring' }" @click="goto('expense_recurring')">
            Recurring
        </li>
      </ul>
    </div>

    <vue-page-transition name="fade">
      <router-view name="expense"></router-view>
    </vue-page-transition>

  </div>
</template>

<script>
import ExpenseHeader from "./includes/HeaderExpense";


export default {
  name: 'Expense',
  components: {ExpenseHeader },
  data() {
    return {
      searchField: '',
      selectAllCheckbox: false,
      editAllow: false,
      selectedCount: 0,
      expensePending: [],
      modals: {
        newExpense: false,
        editExpense: false
      },
      ui: {
        loading: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      setTimeout(() => {
        this.expensePending = [{id: 1, bookkeeper: 'Brooke Allen'}, {id: 2, bookkeeper: 'Brooke Allen'}];
        this.expensePending.map((expense) => {
          expense.checked = false;
        });
        this.ui.loading = false;
      }, 1000)
    },
    selectAll(val) {
      if (val === false) {
        this.expensePending.map((expense) => {
          expense.checked = true;
          this.selectedCount++;
        })
      } else {
        this.expensePending.map((expense) => {
          expense.checked = false;
          this.selectedCount--;
        })
      }
    },
    countSelected(val) {
      if (val === false) {
        this.selectedCount++;
        this.selectAllCheckbox = true;
        if (this.selectedCount === 1) {
          this.editAllow = true;
        }
      } else {
        this.selectedCount--;
        this.editAllow = false;
        if (this.selectedCount === 0) {
          this.selectAllCheckbox = false;
        }
      }
    },
    deleteSelected() {
      const deleteRequests = this.expensePending.filter((request) => {
        return request.checked === true
      });

      if (deleteRequests.length > 0) {
        this.ui.loading = true;
        setTimeout(() => {
          this.ui.loading = false;
          this.selectAllCheckbox = false;
          this.expensePending = [{id: 1, bookkeeper: 'Brooke Allen'}, {id: 2, bookkeeper: 'Brooke Allen'}];
          this.editAllow = false;
        }, 1000);
      } else {
        alert('Pls select')
      }
    },
    deleteExpense(id) {
      console.log(id);
      this.ui.loading = true;
      setTimeout(() => {
        this.expensePending = [{id: 1, bookkeeper: 'Brooke Allen'}, {id: 2, bookkeeper: 'Brooke Allen'}];
        this.expensePending.map((expense) => {
          expense.checked = false;
        });
        this.ui.loading = false;
        this.editAllow = false;
      }, 1000)
    },
    goto(routeName) {
      this.$router.push({name: routeName}).catch(()=>{});
    },
  },
  beforeMount() {
    this.getData();
  },
  computed: {
    page() {
      return this.$route.name
    },
  }
}
</script>

<style lang="scss" scoped>
table.table {
  min-width: 1600px;
  tr {
    vertical-align: middle;
  }
  .status {
    max-width: 95px;
    width: 100%;
    padding: 5px 19px;
    border-radius: 8px;
    &.unverified {
      border: 1px solid rgba(0, 7, 46, 0.2);
      background-color: rgba(0, 7, 46, 0.05);
      color: black;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 21px;
    }
  }
}

.borrower-action-block {
  background: white!important;
  border-right: 8px;
  min-width: 200px;
  .action-label {
    opacity: 0.5;
    color: rgba(0,0,0,1);
    font-size: 0.6875rem;
    letter-spacing: 0;
    line-height: 1.063rem;
  }
  ul {
    padding: 5px 0 7px 0;
    list-style: none;
    margin: 0;

    li {
      text-align: initial;
      padding: 4px 11px;
      img {
        opacity: 0.46;
        height: 18px;
        width: 18px;
        margin-right: 10px;
      }
      &:hover {
        background-color: rgba(0,0,0,.05);
      }
      a {
        display: flex;
        align-items: center;
        color: rgba(0,0,0,1);
        font-size: 0.75rem;
        letter-spacing: 0;
        line-height: 1.125rem;
        text-decoration: none;
      }

    }
  }
}

</style>
