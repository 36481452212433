import Vue from 'vue'
import store from './store/index'
import {getBackendUrl} from "@/utils/backendUrl";
import moment from 'moment';

const backendUrl = getBackendUrl();

Vue.mixin({
  data() {
    return {
      // roles
      ROLE_MLO: 'ROLE_MLO',
      ROLE_BRANCH_MANAGER: 'ROLE_BRANCH_MANAGER',
      ROLE_BOOKKEEPER: 'ROLE_BOOKKEEPER',
      ROLE_FUNDING_REQUEST_PROCESS: 'ROLE_FUNDING_REQUEST_PROCESS',
      ROLE_CEO_ASSISTANT: 'ROLE_CEO_ASSISTANT',
      ROLE_IREFI_CONFIG: 'ROLE_IREFI_CONFIG',
      // end role
      pagination: {
        from: 0,
        to: 0,
        total: 0,
        page: 1,
        perPage: 10
      }
    }
  },
  methods: {
    isGranted(role) {
      let roles = store.getters.getRoles

      return roles.includes(role)
    },
    getCounters() {
      return store.getters.getCounters
    },
    agentPhoto(photo) {
      return backendUrl + photo
    },
    backendUrl(url) {
      return backendUrl + url
    },
    goto(url) {
      window.location = url
    },
    /**
     * @param type - document type that added as key in array in cloud/src/Controller/Api/DownloadController.php
     * @param id - document id from db (from backend)
     * @param filename - usually client filename, visible in modal title
     *
     * Example of usage: openFilePreview('loan_escrow_doc', doc.id, doc.clientFilename)
     */
    openFilePreview(type, id, filename) {
      store.dispatch('OPEN_FILE_PREVIEW_MODAL', {
        url: backendUrl + `/api/v1/download/${type}/${id}?filename=${filename}`,
        filename: filename
      })
    },
    toggleTaskModal(id = null, tabName = null) {
      store.dispatch('TOGGLE_TASK_MODAL', {
        taskId: id,
        tabName: tabName
      })
    },
    getFirstErrorFromResponse(response) {
      if (response.data.error) {
        return response.data.error;
      }
      if (response.data.detail) {
        return response.data.detail;
      }
      if (response.data.errors && response.data.errors.length > 0) {
        let err = response.data.errors[0];
        return err.message;
      }
      return 'Error..';
    },
    getErrorsFromResponse(response) {
      let errors = []
      let error = this.getFirstErrorFromResponse(response)

      if (response.data.errors) {
        errors = response.data.errors
      }

      return {errors: errors, error: error};
    },
    getError(field, errors) {
      let messages = [];
      errors.filter(error => {
        if (error.path === field) {
          messages.push(error.message)
        }
      })

      return messages
    },
    capitalize(string) {
      if (typeof string !== 'string')
        return ''

      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getCrmUrl() {
      let currentDomain = window.location.hostname
      let isStaging = currentDomain.includes('staging')
      let apiUrl = process.env.VUE_APP_CRM_URL
      if (isStaging) {
        apiUrl = apiUrl.replace(
          'crm.clearmortgagecapital.com',
          'crm.staging.clearmortgagecapital.com'
        )
      }

      return apiUrl + '/authorization?token=' + this.$store.getters.getToken;
    },
    convertToCurrency(value, maximumFractionDigits = 2) {
      let res = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: maximumFractionDigits
      }).format(Number.parseFloat(value))

      return (res === '-$0.00' || res === '$0.00') ? '$0' : res
    },
    convertToNumberFormat(value, fractionDigits = 3) {
      return Number(value).toFixed(fractionDigits)
    },
    formatDate(date, format = 'MMM D, Y') {
      return date ? moment(String(date)).format(format) : moment().format(format)
    },
    formatRate(rate) {
      return Number.parseFloat(rate).toFixed(3) + '%'
    },
    timeAgo(date) {
      return date ? moment(String(date)).fromNow() : moment().fromNow()
    },
    toggleCheckboxItem(items, itemId, elementId) {
      let selectedCount = 0

      items.map(item => {
        if (item.id === itemId) {
          item.checked = !item.checked
        }

        if (item.checked) {
          selectedCount++
        }
      })

      let checkbox = document.getElementById(elementId)
      if (selectedCount > 0 && selectedCount < items.length) {
        checkbox.indeterminate = true
      } else if (selectedCount === items.length) {
        checkbox.indeterminate = false
        checkbox.checked = true
      } else {
        checkbox.indeterminate = false
        checkbox.checked = false
      }

      return {
        items: items,
        selectedCount: selectedCount
      }
    },
    alertSuccess(message, delay = 2700) {
      store.dispatch('OPEN_STATUS_NOTIFICATION', {
        msg: message,
        status: 'success',
        delay: delay
      });
    },
    alertError(message, delay = 3500) {
      store.dispatch('OPEN_STATUS_NOTIFICATION', {
        msg: message,
        status: 'error',
        delay: delay
      });
    },
    pushAlert(status, message, delay = 3500) {
      store.dispatch('OPEN_STATUS_NOTIFICATION', {
        status: status,
        msg: message,
        delay: delay
      });
    },
    getFirstError(field, errors) {
      let message = ''
      for (let error of errors) {
        if (error.path === field) {
          message = error.message
          break
        }
      }

      return message
    },
    formatUSNumber(entry = '') {
      if (entry) {
          const match = entry
          .replace(/\D+/g, '')
          .replace(/^1/, '')
          .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
        const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match
        const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : ''
        const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : ''

        return `${part1}${part2}${part3}`
      }
      return ''
    },
    convertObjectToQueryParams(obj) {
      return Object.keys(obj).map(function(key) {
        if (key === 'propertyAddress') {
          // remove # from address
          obj[key] = obj[key].replace('#', '');
        }
        return key + '=' + obj[key];
      }).join('&');
    },
    displayNoneIfEmpty (val) {
      return val ? val : 'N/A'
    },
    numbersOnly(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateEmail(val) {
      //eslint-disable-next-line
      let regx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      if (val && regx.test(val)) {
        return true;
      }
      return false;
    }
  }
})
