<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="text-center mt-5" v-show="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="modal-header" v-show="ui.loading === false">
        <div>
          <h3>
            Add Vendor
          </h3>
          <span class="sub-title">
            Add new vendor details
          </span>
        </div>
      </div>
      <div class="modal-body" id="modal-body-scroll" v-show="ui.loading === false">
        <div class="modal-body-container mt-3">
          <div class="form-group col-md-6">
            <label for="fieldVendorName">
              Name
            </label>
            <input type="text"
                   id="fieldVendorName"
                   placeholder="Enter Vendor Name"
                   v-model="vendor.name"
                   class="form-control">
          </div>
          <div class="row mt-3">
            <label for="">
              Select a Category
            </label>
            <div class="row">
              <div class="col-4 cursor-pointer"
                   v-for="category in formData.categories"
                   @click="setCategory(category.id)"
                   :key="category.id">
                <span class="check-btn"
                      :class="{active: vendor.categoryId === category.id}">
                  {{ category.name }}
                </span>
              </div>
            </div>
          </div>
          <div class="form-group full-width mt-3">
            <label for="fieldVendorAddress">
              Address
            </label>
            <input type="text" class="form-control"
                   placeholder="Enter Address"
                   id="fieldVendorAddress"
                   v-model="vendor.address">
          </div>
          <div class="form-group full-width mt-3">
            <label for="">
              Contacts
            </label>
            <textarea name="" id="" cols="30"
                      v-model="vendor.contacts"
                      rows="6" class="form-control">
            </textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-show="ui.loading === false">
        <button type="submit"
                @click="saveVendor"
                class="btn bg-primary submit">
          <span v-if="ui.form.loading"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
          </span>
          <span v-if="!ui.form.loading">Add Vendor</span>
        </button>
        <button type="reset" class="btn btn-cancel" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: 'AddVendorModal',
  data() {
    return {
      vendor: {
        name: '',
        address: '',
        contacts: '',
        categoryId: null
      },
      ui: {
        loading: false,
        ps: null,
        form: {
          loading: false,
        }
      },
      formData: {
        categories: [
          {
            id: 1,
            name: 'Test'
          },
          {
            id: 2,
            name: 'Test 2'
          },
          {
            id: 3,
            name: 'Test 2'
          },
        ]
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1500)
    },
    setCategory(val) {
      this.vendor.categoryId = val;
    },
    saveVendor() {
      this.ui.form.loading = true;
      setTimeout(() => {
        this.ui.form.loading = false;
      }, 1000)
    }
  },
  created() {
    this.getData();
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
  },
}
</script>

<style lang="scss" scoped>
.check-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
