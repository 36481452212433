<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="text-center mt-5" v-show="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="modal-header" v-show="ui.loading === false">
        <div>
          <h3>
            Add Expense
          </h3>
          <div class="sub-title">
            Submit New Expense
          </div>
        </div>
      </div>
      <div class="modal-body" id="modal-body-scroll" v-show="ui.loading === false">
        <div class="modal-body-container mt-3 border-0">
          <div class="form-group col-md-6">
            <label for="fieldAgent">
              Agent
            </label>
            <multiselect v-model="agent"
                         placeholder="Select Agent"
                         id="fieldAgent"
                         :options="formData.agents"
                         :searchable="true"
                         :close-on-select="true"
                         :allow-empty="false"
                         :show-labels="false">
            </multiselect>
          </div>
          <div class="row mt-3">
            <div class="col-md-5 pe-0">
              <label for="fieldVendor">
                Vendor
              </label>
              <multiselect v-model="vendor"
                           placeholder="Select Vendor"
                           id="fieldVendor"
                           :options="formData.vendors"
                           :searchable="true"
                           :close-on-select="true"
                           :allow-empty="false"
                           :show-labels="false">
              </multiselect>
            </div>
            <div class="col-md-6 ps-0">
              <div class="d-flex align-items-center mt-5 cursor-pointer" @click="$emit('openVendor')">
                <img src="@/assets/icons/icon-add blue.svg" alt="">
                <span>
                  Add New Vendor
                </span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-5">
              <label for="fieldFrom">
                From
              </label>
              <div>
                <date-range-picker
                  ref="picker"
                  v-model="dateRangeFrom"
                  class="daterange-control"
                  id="fieldFrom"
                  opens="center"
                  :singleDatePicker="true"
                  :auto-apply="true"
                  :timePicker="false"
                  :time-picker24-hour="false"
                  :ranges="false"
                  :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
                >

                  <template v-slot:input="picker">
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                    <img src="@/assets/icons/icon-calender.svg" alt="">
                  </template>
                </date-range-picker>
              </div>
            </div>
            <div class="col-md-6">
              <label for="fieldDateTo">
                To
              </label>
              <div>
                <date-range-picker
                  ref="picker"
                  v-model="dateRangeTo"
                  class="daterange-control"
                  id="fieldDateTo"
                  opens="center"
                  :singleDatePicker="true"
                  :auto-apply="true"
                  :timePicker="false"
                  :time-picker24-hour="false"
                  :ranges="false"
                  :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
                >

                  <template v-slot:input="picker">
                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                    <img src="@/assets/icons/icon-calender.svg" alt="">
                  </template>
                </date-range-picker>
              </div>
            </div>
          </div>
          <div class="form-group col-md-6 mt-3">
            <label for="fieldExpenseAmount">Expense Amount</label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text">$</div>
              </div>
              <input type="number"
                     class="form-control prepend "
                     id="fieldExpenseAmount" placeholder="0.00" v-model="expenseAmount">
            </div>
          </div>
          <div class="col-12 mt-3">
            <label for="fieldMemo">
              Memo
            </label>
            <textarea name="" id="fieldMemo"
                      placeholder="Write a memo (Optional)"
                      v-model="memo"
                      class="form-control"
                      cols="30" rows="6"></textarea>
          </div>
          <div class="cursor-pointer mt-3">
            <label for="fieldFile">
              Invoice
            </label>
            <div class="scanned-doc position-relative text-center">
              <input type="file" ref="stips" class="position-absolute" id="fieldFile" multiple>
              <div class="scanned-doc-text d-none">
                <span></span> file(s) selected
              </div>
              <div class="scanned-doc-text">
                Drag File here or <span>Browse</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-start" v-show="ui.loading === false">
        <button class="btn btn-primary" @click="saveExpense" >
          <span v-if="ui.form.loading === true"
                class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true">
          </span>
          <span v-if="ui.form.loading === false">
            Submit Expense
          </span>
        </button>
        <button type="reset" class="btn btn-cancel" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker';
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: 'AddExpensesModal',
  components: { Multiselect, DateRangePicker },
  data() {
    return {
      agent: '',
      vendor: '',
      expenseAmount: '',
      memo: '',
      ui: {
        loading: false,
        ps: null,
        form: {
          loading: false
        }
      },
      dateRangeFrom: {
        startDate: '2019-12-10',
        endDate: '2019-05-02'
      },
      dateRangeTo: {
        startDate: '2019-12-10',
        endDate: '2019-05-02'
      },
      formData: {
        agents: [],
        vendors: []
      }
    }

  },
  methods: {
    getData() {
      this.ui.loading = true;
      setTimeout(() =>{
        this.ui.loading = false;
      }, 1500)
    },
    saveExpense() {
      this.ui.form.loading = true;
      setTimeout(() => {
        this.ui.form.loading = false;
      }, 1000)
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
  },
  filters: {
    date(date) {
      return moment(String(date)).format('MM/DD/YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-body-container::v-deep .multiselect {

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38,111,229,0.5);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__tags {
    padding: 10px 40px 0 8px;
    transition: all .3s;
    &:hover {
      border: 1px solid rgba(38,111,229,0.2);
      box-shadow: 0 0 0 4px rgba(38,111,229,0.1);
    }
  }
  &__select {
    &:before {
      top: 15%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }
  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }
  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}

</style>
